import React, { useState, useEffect } from "react"
import { FaHeart, FaRegHeart } from "react-icons/fa"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import {
  getFeatures,
  likeFeature,
  moveCollections,
  loginAnon,
  onAuthStateChanged,
  subscribeToLikeTotals,
  subscribeToUser,
  unlikeFeature,
} from "./utils"
import timTitleImg3 from "../images/sparkleTitle_730.png"
import loadingTree from "../images/loadingTree6.gif"

//      ...GatsbyImageSharpFluid_tracedSVG

export const cardImage = graphql`
  fragment cardImage on File {
    childImageSharp {
      fluid(maxWidth: 360, maxHeight: 480, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const landscapeCardImage = graphql`
  fragment landscapeCardImage on File {
    childImageSharp {
      fluid(maxHeight: 225, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const galleryImage = graphql`
  fragment galleryImage on File {
    childImageSharp {
      fluid(maxWidth: 850, quality: 100, srcSetBreakpoints: [640, 768]) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

function useAuth() {
  const [authAttempted, setAuthAttempted] = useState(false)
  const [auth, setAuth] = useState(null)

  useEffect(() => {
    return onAuthStateChanged(auth => {
      setAuthAttempted(true)
      setAuth(auth)
    })
  }, [])

  return { auth, authAttempted }
}

function FeatureList() {
  const [features, setFeatures] = useState([])
  const [likeTotals, setLikeTotals] = useState([])
  const [user, setUser] = useState({})

  const [error, setError] = useState(null)
  const { auth, authAttempted } = useAuth()

  loginAnon().catch(error => {
    console.log(error.message)
    setError(error)
  })

  // useEffect(() => {moveCollections();} , []);

  useEffect(() => {
    if (auth) {
      return getFeatures().then(fs => setFeatures(fs))
    }
  }, [auth])

  useEffect(() => {
    if (auth) {
      return subscribeToLikeTotals(setLikeTotals)
    }
  }, [auth])

  useEffect(() => {
    if (auth) {
      return subscribeToUser(auth.uid, setUser)
    }
  }, [auth])

  if (!authAttempted) {
    return <LoadingTree />
  }
  // const totalVotes =
  //   features &&
  //   features.reduce((sum, f) => {
  //     return sum + f.votes;
  //   }, 0);

  // console.log(totalVotes);

  // width={[
  //     "100%", // base
  //     "50%", // 480px upwards
  //     "25%", // 768px upwards
  //     "15%", // 992px upwards
  //   ]}
  // console.table("🌳", JSON.stringify(likeTotals))
  // console.table(user)

  return (
    <div className="flex flex-col items-center justify-center w-screen">
      <div className="min-w-min max-w-xl mt-4 sm:mt-8 mx-auto">
        <div className="mx-auto my-0 mb-8">
          <img
            alt="Lights on Leyden"
            className="mx-auto"
            loading="lazy"
            src={timTitleImg3}
          />
        </div>
        <h1 className="border-gray-400 border-t-4 border-b-4 mb-8 mx-auto rounded-md text-2xl text-blue-50 text-center w-1/2">
          Our Features
        </h1>
        <div className="mx-0 sm:mx-auto w-full">
          {features &&
            features.map(f => {
              return (
                <Card
                  key={f.name}
                  desc={f.description}
                  featureId={f.id}
                  likesIt={user?.likes?.includes(f.id)}
                  numLikes={likeTotals[f.id] || 0}
                  pic={
                    f.images && f.images[f.featuredImage]
                      ? f.images[f.featuredImage]
                      : null
                  }
                  title={f.name}
                  uid={auth.uid}
                />
              )
            })}
        </div>
      </div>
    </div>
  )
}

export function Card({ desc, featureId, likesIt, numLikes, pic, title, uid }) {
  return (
    <div className="mx-auto mb-12">
      <div className="bg-blue-100 dark:bg-gray-900 border-blue-400 dark:border-blue-900 border-t-8 border-b-8 flex flex-col mx-0 max-w-xl sm:rounded shadow-md text-gray-900 dark:text-gray-100 w-full">
        {pic && <FeaturedPhoto pic={pic} />}
        <div className="my-4 px-2 sm:px-6">
          <h2 className="text-2xl text-blue-900 dark:text-blue-200">{title}</h2>
          <div
            className="mt-4 mb-6"
            dangerouslySetInnerHTML={{ __html: desc }}
          ></div>
          <button
            aria-label="Show this feature some love"
            className={`flex flex-row items-center justify-center mr-2 ${
              likesIt
                ? "text-red-600 dark:text-red-500"
                : "text-blue-600 dark:text-blue-200"
            }`}
            onClick={async () => {
              if (likesIt) {
                await unlikeFeature(uid, featureId)
              } else {
                await likeFeature(uid, featureId)
              }
            }}
          >
            {likesIt ? (
              <FaHeart size="1.5em" className="inline" />
            ) : (
              <FaRegHeart size="1.5em" className="inline" />
            )}
            <span className="ml-2 text-blue-600 dark:text-blue-200">
              {numLikes > 0
                ? numLikes > 1
                  ? `${numLikes} people love this`
                  : "1 person loves this"
                : "Love this feature?"}
            </span>
          </button>
          {/* <button
            aria-label="Show this feature some love"
            className="border-2 border-blue-600 dark:border-blue-200 flex flex-row items-center h-8 mt-2 px-2 rounded-md text-blue-600 dark:text-blue-200"
          >
            <FaRegHeart className="inline mb-1" />
            <span className="ml-2">Love it</span>
          </button> */}
        </div>
      </div>
    </div>
  )
}

function FeaturedPhoto({ pic }) {
  const data = useStaticQuery(graphql`
    query {
      bumblesCard: file(relativePath: { eq: "features/bumbles_card.jpg" }) {
        ...landscapeCardImage
      }
      bumblesCard850: file(
        relativePath: { eq: "features/bumbles_card-850.jpg" }
      ) {
        ...galleryImage
      }
      candyCanesCard: file(
        relativePath: { eq: "features/candy_canes_card.jpg" }
      ) {
        ...landscapeCardImage
      }
      candyCanesCard850: file(
        relativePath: { eq: "features/candy_canes_card-850.jpg" }
      ) {
        ...galleryImage
      }
      candyCanes2_850: file(
        relativePath: { eq: "features/candy_canes_2-850.png" }
      ) {
        ...galleryImage
      }
      dwarfSpruce1: file(relativePath: { eq: "features/dwarf_spruce_1.png" }) {
        ...galleryImage
      }
      ferrisWheel1: file(relativePath: { eq: "features/ferris_wheel_1.png" }) {
        ...galleryImage
      }
      ferrisWheel3: file(relativePath: { eq: "features/ferris_wheel_3.png" }) {
        ...galleryImage
      }
      magicTreeCard: file(
        relativePath: { eq: "features/magic_tree_card.jpg" }
      ) {
        ...cardImage
      }
      magicTreeCard850: file(
        relativePath: { eq: "features/magic_tree_card-850.jpg" }
      ) {
        ...galleryImage
      }
      packages1: file(relativePath: { eq: "features/packages_card.jpg" }) {
        ...cardImage
      }
      penguinsCard: file(relativePath: { eq: "features/penguins_card.jpg" }) {
        ...landscapeCardImage
      }
      penguinsCard850: file(
        relativePath: { eq: "features/penguins_card-850.jpg" }
      ) {
        ...galleryImage
      }
      polarBearsCard: file(
        relativePath: { eq: "features/polar_bears_card.jpg" }
      ) {
        ...landscapeCardImage
      }
      polarBearsCard850: file(
        relativePath: { eq: "features/polar_bears_card-850.jpg" }
      ) {
        ...galleryImage
      }
      reindeerCard: file(relativePath: { eq: "features/reindeer_card.jpg" }) {
        ...landscapeCardImage
      }
      reindeerCard850: file(
        relativePath: { eq: "features/reindeer_card-850.jpg" }
      ) {
        ...galleryImage
      }
      santaClausCard: file(
        relativePath: { eq: "features/santa_claus_card.jpg" }
      ) {
        ...landscapeCardImage
      }
      santaClausCard850: file(
        relativePath: { eq: "features/santa_claus_card-850.jpg" }
      ) {
        ...galleryImage
      }
      skiHillCard: file(relativePath: { eq: "features/ski_hill_card.jpg" }) {
        ...landscapeCardImage
      }
      skiHillCard850: file(
        relativePath: { eq: "features/ski_hill_card-850.jpg" }
      ) {
        ...galleryImage
      }
      snowPeopleCard: file(
        relativePath: { eq: "features/snowpeople_card.jpg" }
      ) {
        ...landscapeCardImage
      }
      snowPeopleCard850: file(
        relativePath: { eq: "features/snowpeople_card-850.jpg" }
      ) {
        ...galleryImage
      }
      stockingsCard: file(relativePath: { eq: "features/stockings_card.jpg" }) {
        ...landscapeCardImage
      }
      stockingsCard850: file(
        relativePath: { eq: "features/stockings_card-850.jpg" }
      ) {
        ...galleryImage
      }
      trainCard: file(relativePath: { eq: "features/train_card.jpg" }) {
        ...landscapeCardImage
      }
      trainCard850: file(relativePath: { eq: "features/train_card-850.jpg" }) {
        ...galleryImage
      }
      wreathCard: file(relativePath: { eq: "features/wreath_card.png" }) {
        ...galleryImage
      }
    }
  `)

  const baseSrc = pic.srcs.find(src => src.res === 0)

  if (!data[baseSrc.queryName]?.childImageSharp?.fluid) {
    return null
  }

  const sources = pic.srcs.map(src =>
    src.res
      ? {
          ...data[src.queryName].childImageSharp.fluid,
          media: `(min-width: ${src.res}px)`,
        }
      : data[src.queryName].childImageSharp.fluid
  )

  return <Img fluid={sources} alt={baseSrc.caption} w="100%" />
}

function LoadingTree() {
  return (
    <div
      style={{
        display: "grid",
        placeItems: "center",
        minHeight: "100vh",
        height: "-webkit-fill-available",
        width: "100vw",
      }}
    >
      <img src={loadingTree} />
    </div>
  )
}

export default FeatureList

// <picture>
//   <>
//     {pic.srcs
//       .filter(src => Boolean(src.res))
//       .map(src => {
//         console.log(`d`, data)
//         console.log(src)
//         return (
//           // <Img
//           //   fluid={data[baseSrc.queryName].childImageSharp.fluid}
//           //     key={src.queryName}
//           //   alt={baseSrc.caption}
//           //   w="100%"
//           // />
//           <source
//             key={src.queryName}
//             media={`(min-width: ${src.res}px)`}
//             srcSet={`features/${src.queryName}`}
//           />
//         )
//       })}
//   </>
//   <Img
//     fluid={data[baseSrc.queryName].childImageSharp.fluid}
//     alt={baseSrc.caption}
//     w="100%"
//   />
// </picture>
